/* font styling */
.light-text {
    font-family: 'Inter', sans-serif;
    font-weight: 300; /* Light weight */
  }
  
  /* For regular text */
  .regular-text {
    font-family: 'Inter', sans-serif;
    font-weight: 400; /* Regular weight */
  }
  
  /* For semi-bold text */
  .semi-bold-text {
    font-family: 'Inter', sans-serif;
    font-weight: 600; /* Semi-bold weight */
  }
  
  /* For bold text */
  .bold-text {
    font-family: 'Inter', sans-serif;
    font-weight: 700; /* Bold weight */
  }

  /* For extra bold text */
  .extra-bold-text {
    font-family: 'Inter', sans-serif;
    font-weight: 800; /* Bold weight */
  }

.design-approach-section {
    padding: 60px 20px;
    background-color: #F9F9FF;
    text-align: center;
    position:relative;
    border-top: 2px solid #e0e0e0; /* Line at the top */
    border-bottom: 2px solid #e0e0e0; /* Line at the bottom */
  }
  
  .design-approach-section h2 {
    margin-bottom: 40px;
    color: #333;
    position: relative;
    font-size: 35px; /* Font Size */
    line-height: 55px; /* Line Height */
    letter-spacing: 0px; /* Letter Spacing */
  }
  
  .design-approach-section h2 span {
    color: #800080; /* Accent color for "development approach" */
    font-size: 35px; /* Font Size */
    line-height: 55px; /* Line Height */
    letter-spacing: 0px; /* Letter Spacing */
  }

  .design-approach-section h2::before {
    content: '';
    width: 50px;
    height: 4px;
    background: linear-gradient(to right, #800080, #e91e63);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-20px);
    border-radius: 2px;
  }
  
  .approach-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 cards per row */
    gap: 30px;
    max-width: 80%; /* Limit the width of the grid to 70% of the container */
    margin: 0 auto; /* Center the grid horizontally */
  }
  
  .approach-card {
    display: flex;
    align-items:flex-start;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    height: 100%;
  }
  
  .approach-icon {
    flex-shrink: 0;
    margin: 20px;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
  }
  
  .approach-icon img {
    width: 20px;
    height: 20px;
  }

  .approach-icon-ux{
    /* background-color: #27272E; */
    background: linear-gradient(45deg,#27272E,#29272E);
    border-radius: 10px;
  }
  .approach-icon-shared{
    background: linear-gradient(45deg,#509CF5,#68DBF2);
    border-radius: 10px;
  }
  .approach-icon-exp{
    background: linear-gradient(45deg,#FF3D9A,#FF92AE);
    border-radius: 10px;
  }
  .approach-icon-security{
    background: linear-gradient(45deg,#24E795,#67E9F1);
    border-radius: 10px;
  }
  .approach-icon-review{
    background: linear-gradient(45deg,#F7936F,#FFEF5E);
    border-radius: 10px;
  }
  .approach-icon-testing{
    background: linear-gradient(45deg,#57007B,#F76680);
    border-radius: 10px;
  }
  
  .approach-details {
    display: flex;
    flex-direction: column;
    justify-content:left;
    align-items: flex-start;
    text-align: left;
    padding-right: 10px;
  }
  
  .approach-details h3 {
    /* margin-bottom: 10px; */
    color: #1A202C;
    margin-bottom: 5px; /* Reduce the space below the title */
    font-size: 20px; /* Font Size */
    line-height: 136.5%; /* Line Height */
    letter-spacing: 0px; /* Letter Spacing */
  }
  
  .approach-details p {
    color: #4A5568;
    line-height: 1.6;
    margin-top: 10px; /* Ensure there's no extra space above the description */
    font-size: 14px; /* Font Size */
    line-height: 160%; /* Line Height */
    letter-spacing: 0px; /* Letter Spacing */
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .approach-grid {
      grid-template-columns: 1fr; /* 1 card per row on mobile */
    }
  
    .approach-card {
      flex-direction: column;
      align-items: center;
    }
  
    .approach-icon {
      margin-bottom: 10px;
    }
  
    .approach-details {
      align-items: center;
      text-align: center;
    }
  }

  