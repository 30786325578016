/* font styling */
.light-text {
  font-family: 'Inter', sans-serif;
  font-weight: 300; /* Light weight */
}

/* For regular text */
.regular-text {
  font-family: 'Inter', sans-serif;
  font-weight: 400; /* Regular weight */
}

/* For semi-bold text */
.semi-bold-text {
  font-family: 'Inter', sans-serif;
  font-weight: 600; /* Semi-bold weight */
}

/* For bold text */
.bold-text {
  font-family: 'Inter', sans-serif;
  font-weight: 700; /* Bold weight */
}

/* For extra bold text */
.extra-bold-text {
  font-family: 'Inter', sans-serif;
  font-weight: 800; /* Bold weight */
}

.services-section {
    padding: 60px 20px;
    background-color: #F9F9FF;
    text-align: center;
    position: relative;
    border-top: 2px solid #e0e0e0; /* Line at the top */
    border-bottom: 2px solid #e0e0e0; /* Line at the bottom */
  }
  
  .title-bar {
    width: 50px; /* Adjust the width to match the design */
    height: 4px; /* Adjust the height to match the design */
    background: linear-gradient(to right, #800080, #e91e63); /* Purple gradient */
    margin: 0 auto 20px auto; /* Center the bar and add margin at the bottom */
    border-radius: 2px; /* Round the edges of the bar */
  }
  
  .services-section h2 {
    margin-bottom: 40px;
    color: #333;
    font-size: 35px; /* Font Size */
    line-height: 55px; /* Line Height */
    letter-spacing: 0px; /* Letter Spacing */
  }
  
  .services-container {
    display: flex;
    justify-content: flex-start;
    overflow-x: auto;
    scroll-snap-type:x mandatory;
    gap: 20px;
    padding: 20px;
    margin-bottom: 15px;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
  }

  .services-container::-webkit-scrollbar{
    display: none;
  }
  
  .service-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 30px;
    /* min-width: 300px; */
    width: 250px; /* Fixed width for the cards */
    flex-shrink: 0;
    scroll-snap-align: center;
    transition: transform 0.3s ease, margin 0.3s ease;
    position: relative;
    cursor: pointer;
  }
  
  .service-card.highlighted {
    border: 2px solid;
    border-image: linear-gradient(45deg, #57007B, #F76680);
    border-image-slice: 1;
    /* border-color: -webkit-linear-gradient(45deg,#57007B, #F76680); */
    transform: translateY(10px);
  }

  .service-card.service-card.highlighted h3{
    
    background: -webkit-linear-gradient(45deg,#57007B, #F76680);
    -webkit-background-clip: text;
    background-clip: text; /* added this line since the above line shows warning that add this line for compatability with chrome and safari 3*/
    -webkit-text-fill-color: transparent;
  }
  
  .service-card img {
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
  }
  
  .service-card h3 {
    margin-bottom: 15px;
    color: #333;
    font-size: 20px; /* Font Size */
    line-height: 136.5%; /* Line Height */
    letter-spacing: 0px; /* Letter Spacing */
  }
  
  .service-card p {
    color: #666;
    font-size: 14px; /* Font Size */
    line-height: 162%; /* Line Height */
    letter-spacing: 0px; /* Letter Spacing */
  }
  
  .dots-navigation {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .dot {
    width: 10px;
    height: 10px;
    background-color: #ddd;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .dot.active {
    background: linear-gradient(45deg, #F76680, #57007B);
  }
  
  .progress-navigation {
    display: flex;
    justify-content: right;
    align-items: right;
    margin-top: 20px;
    margin-right: 80px;
  }
  
  .progress-navigation span {
    font-size: 1rem;
    color: #666;
    margin: 0 10px;
  }
  
  .progress-bar {
    width: 100px;
    height: 4px;
    background-color: #ddd;
    position: relative;
  }
  
  .progress {
    height: 100%;
    background-color: #800080;
    position: absolute;
    top: 0;
    left: 0;
    transition: width 0.3s ease;
  }
  
  @media (max-width: 768px) {
    .services-container {
      padding: 0;
      gap: 10px;
    }
  
    .service-card {
      min-width: 50%;
      padding: 20px;
    }

    .service-card.highlighted {
      border: 2px solid #800080;
      transform: translateY(0px);
    }

    .services-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 60px 20px;
        background-color: #f9f9f9;
        text-align: center;
        position: relative;
        border-top: 2px solid #0a0909; /* Line at the top */
        border-bottom: 2px solid #e0e0e0; /* Line at the bottom */
      }
      .progress-navigation {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        margin-right: 0px;
      }
  }
  