/* font styling */
.light-text {
    font-family: 'Inter', sans-serif;
    font-weight: 300; /* Light weight */
  }
  
  /* For regular text */
  .regular-text {
    font-family: 'Inter', sans-serif;
    font-weight: 400; /* Regular weight */
  }
  
  /* For semi-bold text */
  .semi-bold-text {
    font-family: 'Inter', sans-serif;
    font-weight: 600; /* Semi-bold weight */
  }
  
  /* For bold text */
  .bold-text {
    font-family: 'Inter', sans-serif;
    font-weight: 700; /* Bold weight */
  }

  /* For extra bold text */
  .extra-bold-text {
    font-family: 'Inter', sans-serif;
    font-weight: 800; /* Bold weight */
  }

  .title-bar {
    width: 50px; /* Adjust the width to match the design */
    height: 4px; /* Adjust the height to match the design */
    background: linear-gradient(to right, #800080, #e91e63); /* Purple gradient */
    margin: 0 auto 20px auto; /* Center the bar and add margin at the bottom */
    border-radius: 2px; /* Round the edges of the bar */
  }

.product-launches-section {
    padding: 60px 20px;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .product-launches-section h2 {
    /* font-size: 2.5rem; */
    /* font-weight: bold; */
    margin-bottom: 40px;
    color: #333;
    font-size: 35px; /* Font Size */
    line-height: 55px; /* Line Height */
    letter-spacing: 0px; /* Letter Spacing */
  }
  
  .product-launches-section h2 span {
    color: #800080; /* Accent color for "Product Launches" */
    font-size: 35px; /* Font Size */
    line-height: 55px; /* Line Height */
    letter-spacing: 0px; /* Letter Spacing */
  }

  .product-launches-section h2::before {
    content: '';
    width: 50px;
    height: 4px;
    background: linear-gradient(to right, #800080, #e91e63);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-20px);
    border-radius: 2px;
  }
  
  .product-card-quickfeed {
    display: flex;
    align-items: center;
    justify-content:center;
    background-color: #fef7ea;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
    /* padding: 20px; */
    transition: transform 0.3s ease;
    height: 400px; /* Set a fixed height for the product card */
    max-width: 70%; /* Limit the width of the card to 70% */
    margin-left: auto; /* Center horizontally */
    margin-right: auto; /* Center horizontally */
  }
  
  
  .product-card-aroundus {
    display: flex;
    align-items: center;
    justify-content:center;
    /* background-color:#C5CBED; */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
    /* padding: 20px; */
    transition: transform 0.3s ease;
    height: 400px; /* Set a fixed height for the product card */
    max-width: 70%; /* Limit the width of the card to 70% */
    margin-left: auto; /* Center horizontally */
    margin-right: auto; /* Center horizontally */
  }
  

  .product-card-quickfeed:hover {
    transform: translateY(-10px); /* Lift effect on hover */
  }

  .product-card-aroundus:hover {
    transform: translateY(-10px); /* Lift effect on hover */
  }

  .product-image, .product-details {
    flex: 1; /* Make both divs take up equal space */
    max-width: 50%; /* Limit the width to 50% */
    box-sizing: border-box;
  }
  
  .product-image {
    /* padding-right: 20px; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Ensure the product-image div occupies full height */
  }
  
  .product-image img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the entire div */
    border-radius: 10px;
  }
  
  .product-details {
    padding-left: 20px; /* Add some spacing between the image and details */
    padding-right: 10px;
    text-align: left;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    /* background-color: #fef7ea; */
    position: relative; /* Enable positioning inside this div */
    padding-bottom: 50px; /* Add extra padding to make space for the "Read more" link */
  }
  
  .product-details h3 {
    /* font-size: 1.8rem; */
    /* font-weight: 600; */
    margin-bottom: 15px;
    color: #333;
    font-size: 28px; /* Font Size */
    line-height: 136%; /* Line Height */
    letter-spacing: 0px; /* Letter Spacing */
  }
  
  .product-details p {
    /* font-size: 1rem; */
    color: #666;
    margin-bottom: 20px;
    line-height: 1.6;
    font-size: 14px; /* Font Size */
    line-height: 162%; /* Line Height */
    letter-spacing: 0px; /* Letter Spacing */
  }

  .product-detail-quickfeed{
    background-color: #fef7ea;
  }

  .product-detail-aroundus{
    background-color: #E3E6F6;
  }
  
  .read-more {
    /* font-weight: bold; */
    color: #800080;
    text-decoration: none;
    position: absolute; /* Positioning the link */
    bottom: 20px; /* 20px from the bottom */
    right: 20px; /* 20px from the right */
  }
  
  .read-more:hover {
    text-decoration: underline;
  }
  
  /* Responsive design adjustments */
  @media (max-width: 768px) {
    .product-card-quickfeed {
      flex-direction: column;
      align-items: center;
      height: auto; /* Allow height to adjust based on content */
      max-width: 100%; /* Take full width on mobile */
    }

    .product-card-aroundus {
        flex-direction: column;
        align-items: center;
        height: auto; /* Allow height to adjust based on content */
        max-width: 100%; /* Take full width on mobile */
      }

    .product-image, .product-details {
        max-width: 100%; /* Make full width on small screens */
        padding-right: 10px;
        padding-left: 0px;
      }
  
    .product-image img {
      width: 100%;
      margin-bottom: 20px;
    }
  
    .product-details {
      text-align: center;
      max-width: 100%;
      padding-bottom: 20px;
    }

    .read-more {
        position: relative; /* Change to relative positioning for mobile */
        bottom: auto;
        right: auto;
        align-self: flex-end; /* Aligns the "Read more" link to the bottom-right of the content */
        margin-top: auto; /* Pushes the link to the bottom of the div */
    }
  }
  