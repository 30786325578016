/* font styling */
.light-text {
    font-family: 'Inter', sans-serif;
    font-weight: 300; /* Light weight */
  }
  
  /* For regular text */
  .regular-text {
    font-family: 'Inter', sans-serif;
    font-weight: 400; /* Regular weight */
  }
  
  /* For semi-bold text */
  .semi-bold-text {
    font-family: 'Inter', sans-serif;
    font-weight: 600; /* Semi-bold weight */
  }
  
  /* For bold text */
  .bold-text {
    font-family: 'Inter', sans-serif;
    font-weight: 700; /* Bold weight */
  }

  /* For extra bold text */
  .extra-bold-text {
    font-family: 'Inter', sans-serif;
    font-weight: 800; /* Bold weight */
  }

.hero-section {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 40px 20px;
    background-color: #f9f9f9;
    box-sizing: border-box;
  }
  
  .hero-content {
    max-width: 50%;
  }
  
  .hero-content h1 {
    font-size: 45px; /* Font Size */
    line-height: 71px; /* Line Height */
    letter-spacing: 0px; /* Letter Spacing */
    color: #1A202C;
  }

  .fontcss{
    font-size: 53px; /* Font Size */
    line-height: 71px; /* Line Height */
    letter-spacing: 0px; /* Letter Spacing */
  }
  
  .hero-content .highlight {
        background: -webkit-linear-gradient(#0D1C9F, #DE4396);
        -webkit-background-clip: text;
        background-clip: text; /* added this line since the above line shows warning that add this line for compatability with chrome and safari 3*/
        -webkit-text-fill-color: transparent;
  }

  .highlight1{
        background: -webkit-linear-gradient(#406AFF, #F7666F);
        -webkit-background-clip: text;
        background-clip: text; /* added this line since the above line shows warning that add this line for compatability with chrome and safari 3*/
        -webkit-text-fill-color: transparent;
  }
  
  .hero-content p {
    font-size: 1.2rem;
    color: #666;
    margin: 20px 0;
  }
  
  .cta-button {
    background: #3D63EA;
    color: white;
    padding: 15px 30px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    display: inline-block;
    margin-top: 20px;
  }
  
  .cta-button:hover {
    opacity: 0.9;
  }
  
  .hero-image {
    max-width: 45%;
  }
  
  .hero-image img {
    width: 100%;
    height: auto;
  }
  
  .semi-circle {
    position: relative;
    width: 100px;
    height: 50px;
    background-color: #800080; /* Adjust color to match your design */
    border-radius: 100px 100px 0 0;
    bottom: -50px; /* Adjust position */
    left: 20px; /* Adjust position */
  }

  /* Mobile View Adjustments */
  @media (max-width: 768px) {
    .hero-section {
      flex-direction: column;
      text-align: center;
    }
  
    .hero-content {
      max-width: 100%;
      margin-bottom: 20px;
    }
  
    .hero-image {
      max-width: 100%;
    }
  }
  