/* Attributes.css */

.attributes-container {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.attributes-container h1 {
  text-align: center;
  color: #000; /* Black text */
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  margin-bottom: 20px;
}

.attributes-container p {
  color: #000; /* Black text */
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  text-align: left;
  margin-bottom: 30px;
}

.attributes-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.attribute-card {
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  background-color: #f9f9f9;
}

.attribute-card p {
  font-family: 'Inter', sans-serif;
  color: #000; /* Black text */
  margin: 6px 0;
}

.license-info {
  margin-top: 12px;
  font-size: 14px;
  color: #000; /* Black text */
}

.attribute-card a {
  color: #007bff;
  text-decoration: none;
}

.attribute-card a:hover {
  text-decoration: underline;
}

/* Pagination Controls */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 15px;
}

.pagination button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  transition: background-color 0.3s;
}

.pagination button:hover {
  background-color: #0056b3;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination span {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: #000; /* Black text */
}
