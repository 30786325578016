/* Footer.css */
/* font styling */
.light-text {
    font-family: 'Inter', sans-serif;
    font-weight: 300; /* Light weight */
  }
  
  /* For regular text */
  .regular-text {
    font-family: 'Inter', sans-serif;
    font-weight: 400; /* Regular weight */
  }
  
  /* For semi-bold text */
  .semi-bold-text {
    font-family: 'Inter', sans-serif;
    font-weight: 600; /* Semi-bold weight */
  }
  
  /* For bold text */
  .bold-text {
    font-family: 'Inter', sans-serif;
    font-weight: 700; /* Bold weight */
  }

  /* For extra bold text */
  .extra-bold-text {
    font-family: 'Inter', sans-serif;
    font-weight: 800; /* Bold weight */
  }

.footer-section {
    background-color: #f9f9f9;
    padding: 60px 20px;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap; /* Ensures it wraps on smaller screens */
  }

  .footer-logo{
    display: flex;
    align-items: center;
  }

  .footer-logo-section{
   display: flex;
   flex-direction: column;
   align-items: flex-start; 
  }

  .footer-logo img {
    width: 40px;
    /* margin-bottom: 20px; */
    margin-right: 10px;
  }
  
  .footer-logo-section p {
    /* margin-bottom: 20px; */
    color: #666; /* Text color */
  }
  
  /* .google-badge img {
    max-width: 100px; 
  } */
  
  .footer-links h3,
  .footer-contact h3 {
    color: #333; /* Heading color */
    margin-bottom: 20px;
  }
  
  .footer-links ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-links ul li {
    margin-bottom: 10px;
    font-family: 'Inter', sans-serif;
    font-weight: 400; /* Regular weight */
  }
  
  .footer-links ul li a {
    text-decoration: none;
    color: #718096; /* Link color */
  }
  
  .footer-links ul li a:hover {
    text-decoration: underline;

  }
  
  .footer-contact p {
    margin-bottom: 10px;
    color: #666; /* Text color */
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 40px;
    border-top: 1px solid #e0e0e0;
    padding-top: 20px;
    color: #666; /* Footer text color */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    .footer-logo{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 0px;
      }
    
      .footer-logo-section{
       display: flex;
       flex-direction: column;
       align-items: center; 
      }
      .footer-logo h2{
        margin-bottom: 0px;
      }
      .footer-logo-section p{
        margin-top: 5px;
      }
  }
  