/* font styling */
.light-text {
    font-family: 'Inter', sans-serif;
    font-weight: 300; /* Light weight */
}

.regular-text {
    font-family: 'Inter', sans-serif;
    font-weight: 400; /* Regular weight */
}

.semi-bold-text {
    font-family: 'Inter', sans-serif;
    font-weight: 600; /* Semi-bold weight */
}

.bold-text {
    font-family: 'Inter', sans-serif;
    font-weight: 700; /* Bold weight */
}

.extra-bold-text {
    font-family: 'Inter', sans-serif;
    font-weight: 800; /* Extra bold weight */
}

.qf-privacy-policy-container {
    padding: 30px;
    background-color: #fff; /* Background color */
    color: #000; /* Text color */
    border-radius: 8px;
    max-width: 75%;
    margin: 50px auto;
}

.qf-privacy-policy-container h1 {
    text-align: center;
    color: #2c3e50; /* Primary heading color */
    margin-bottom: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 700; /* Bold weight */
}

.qf-privacy-policy-container h2 {
    color: #000; /* Secondary heading color */
    margin-top: 30px;
    margin-bottom: 15px;
    font-size: 1.5em;
    border-bottom: 2px solid #eaeaea; /* Subtle underline */
    padding-bottom: 5px;
    font-family: 'Inter', sans-serif;
    font-weight: 600; /* Bold weight */
}

.qf-privacy-policy-container p {
    line-height: 1.8;
    margin-bottom: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 400; /* Regular weight */
}

.qf-privacy-policy-container a {
    color: #3498db; /* Link color */
    text-decoration: none;
}

.qf-privacy-policy-container a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .qf-privacy-policy-container {
        margin: 20px;
        padding: 20px;
    }

    .qf-privacy-policy-container h1 {
        font-size: 1.8em;
    }

    .qf-privacy-policy-container h2 {
        font-size: 1.3em;
    }
}
