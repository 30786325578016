/* General Styles (Desktop and larger screens) */
.cta-banner {
  background-color: #f5f9ff; 
  padding: 40px 20px;
  border-radius: 10px;
  text-align: center;
  margin: 40px auto; 
  width: 70%; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}

.cta-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cta-content h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0;
  color: #333; 
  text-align: left; 
  flex: 1; 
}

.cta-button {
  background: linear-gradient(to right, #ff7a18, #e91e63); 
  color: #fff;
  padding: 12px 30px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  margin-left: 20px;
}

.cta-button:hover {
  transform: translateY(-5px);
}

/* Responsive Styles (Mobile and smaller screens) */
@media (max-width: 768px) {
  .cta-banner {
    width: 90%; /* Make the banner take up more space */
    padding: 20px 10px; /* Reduce padding for smaller screens */
  }

  .cta-content {
    flex-direction: column; /* Stack the text and button vertically */
    align-items: center; /* Center-align the content */
    text-align: center; /* Ensure text is centered */
  }

  .cta-content h2 {
    font-size: 1.5rem; /* Slightly smaller text */
    margin-bottom: 20px; /* Add space below the text */
  }

  .cta-button {
    margin-left: 0;
    padding: 10px 25px;
    font-size: 1rem; /* Slightly smaller button */
  }
}
