/* font styling */
.light-text {
    font-family: 'Inter', sans-serif;
    font-weight: 300; /* Light weight */
  }
  
  /* For regular text */
  .regular-text {
    font-family: 'Inter', sans-serif;
    font-weight: 400; /* Regular weight */
  }
  
  /* For semi-bold text */
  .semi-bold-text {
    font-family: 'Inter', sans-serif;
    font-weight: 600; /* Semi-bold weight */
  }
  
  /* For bold text */
  .bold-text {
    font-family: 'Inter', sans-serif;
    font-weight: 700; /* Bold weight */
  }

  /* For extra bold text */
  .extra-bold-text {
    font-family: 'Inter', sans-serif;
    font-weight: 800; /* Bold weight */
  }


  .development-flow-section {
    padding: 60px 20px;
    background-color: #f9f9f9;
    text-align: center;
    border-bottom: 2px solid #e0e0e0; /* Line at the bottom */
  }
  
  .development-flow-section h2 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 40px;
    color: #333;
  }
  
  .development-flow-section h2 span {
    color: #800080; /* Accent color for "through Khpt works" */
  }


  .flow-container {
    position: relative;
    max-width: 95%;
    /* margin: 0 auto; */
  }
  
  .top-flow-row {
    display: flex;
    justify-content: space-around;
    /* margin-bottom: 60px; */
    margin-left: 0px;
  }
  
  .bottom-flow-row {
    display: flex;
    justify-content: space-around;
    margin-bottom: 60px;
    margin-left:60px;
  }
  
  .flow-step {
    width: 30%; /* Each card takes up 30% of the row */
    position: relative;
  }
  
  .flow-card {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 5px;
    text-align: left;
    transition: transform 0.3s ease;
  }
  
  .flow-card h3 {
    margin-bottom: 10px;
    color: #800080; /* Accent color for step number */
  }
  
  .flow-card h3 span {
    color: #333; /* Title color */
  }
  
  .flow-card p {
    color: #666;
    line-height: 1;
  }
  
  /* .flow-card:hover {
    transform: translateY(-10px); 
  } */
  
  .top-line-connectors{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  
  .bottom-line-connectors{
    padding-left: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .flow-line-connector {
    top: 50%;
    width: 2px; /* Connector line width, adjusted to avoid overlap with cards */
    height: 40px;
    background: linear-gradient(to right, #800080, #e91e63);
  }
  
  .flow-step.left .flow-line-connector {
    left: 100%;
  }
  
  .flow-step.right .flow-line-connector {
    right: 100%;
  }
  
  .flow-main-line {
    position: relative;
    top: 50%;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(to right, #800080, #e91e63);
  }
  
  .trophy-icon {
    position: absolute;
    top: 50%;
    right: -40px;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
  }
  
  @media (max-width: 768px) {
    .flow-row {
      flex-direction: column;
      align-items: center;
    }
  
    .flow-step {
      width: 100%;
      margin-bottom: 20px;
    }
  
    .flow-line-connector {
      display: none;
    }
  
    .flow-main-line {
      display: none;
    }
  
    .trophy-icon {
      right: 10px;
      top: auto;
      bottom: -20px;
      transform: translateY(0);
    }
  }
  
  