.scroll-to-top {
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 1000;
    cursor: pointer;
  }
  
  .scroll-to-top-button {
    border-radius: 60%;
    padding: 10px;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
    transition: transform 0.3s, opacity 0.3s;
  }
  
  .scroll-to-top-button:hover {
    transform: translateY(-5px); 
    opacity: 0.8;
  }
  
  .scroll-icon {
    width: 50px; /* Adjust size as needed */
    height: 50px; /* Adjust size as needed */
    transition: opacity 0.3s ease;
  }
  
  /* Media query for smaller screens (mobile) */
  @media (max-width: 768px) {
    .scroll-to-top {
      bottom: 10px; /* Move closer to the bottom */
      right: 10px;  /* Move closer to the right edge */
    }
  
    .scroll-icon {
      width: 40px; /* Adjust size for mobile */
      height: 40px; /* Adjust size for mobile */
    }
  }
  