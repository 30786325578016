/* font styling */
.light-text {
  font-family: 'Inter', sans-serif;
  font-weight: 300; /* Light weight */
}

/* For regular text */
.regular-text {
  font-family: 'Inter', sans-serif;
  font-weight: 400; /* Regular weight */
}

/* For semi-bold text */
.semi-bold-text {
  font-family: 'Inter', sans-serif;
  font-weight: 600; /* Semi-bold weight */
}

/* For bold text */
.bold-text {
  font-family: 'Inter', sans-serif;
  font-weight: 700; /* Bold weight */
}

/* For extra bold text */
.extra-bold-text {
  font-family: 'Inter', sans-serif;
  font-weight: 800; /* Bold weight */
}

.contact-us-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 40px;
  background-color: #f5f5f5; /* Replace with your theme background color */
}

.contact-form {
  flex: 2;
  padding: 20px;
  background-color: #ffffff; /* Replace with your form background color */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 50%;
  margin: 0 auto;
}

.contact-form h2 {
  margin-bottom: 20px;
  color: #333; /* Your theme text color */
}

.contact-form p {
  margin-bottom: 20px;
  color: #666; /* Your theme text color */
}

.form-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.form-row input, .form-row select, .form-row textarea {
  flex: 1;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-row textarea {
  resize: vertical;
}

.form-row select {
  width: 70%; /* Adjust the width as per your preference */
  padding: 10px; /* Adjust padding for better spacing */
  margin-left: 15px; /* Create a gap between the label and dropdown */
}

.cta-button-contactus {
  padding: 12px 24px;
  background: linear-gradient(45deg, #6675F7, #57007B); /* Your primary button color */
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cta-button-contactus:hover {
  background: linear-gradient(45deg, #6675F7, #57007B); /* Darker shade for hover */
}

@media (max-width: 768px) {
  .contact-form {
    max-width: 90%; /* Adjust the width to fit mobile screens better */
    padding: 15px;
  }

  .form-row {
    display: flex;
    flex-direction: column; /* Stack the inputs vertically */
    justify-content: center;
    align-items: center; /* Make inputs take full width */
    padding: 5px;
  }

  .form-row input, .form-row select, .form-row textarea {
    margin-right: 0; /* Remove the right margin */
    margin-bottom: 10px; /* Add some spacing between stacked inputs */
    width: 90%; /* Ensure the inputs take the full width */
  }

  .form-row select {
    width: 100%; /* Adjust the width for the dropdown */
    margin-left: 0; /* Remove the left margin */
  }
}