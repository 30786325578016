/* FAQ page styling */

.faq-container {
    padding: 30px;
    background-color: #fff;
    color: #000;
    border-radius: 8px;
    max-width: 75%;
    margin: 50px auto;
}

.faq-container h1 {
    text-align: center;
    color: #2c3e50;
    margin-bottom: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
}

.faq-item {
    margin-bottom: 15px;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px;
}

.faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 10px 0;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    color: #333;
}

.faq-question h2 {
    font-size: 1.1em;
    margin: 0;
}

.faq-question span {
    font-size: 1.5em;
    color: #888;
}

.faq-answer {
    padding: 10px 0;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    line-height: 1.6;
    color: #555;
}

@media (max-width: 768px) {
    .faq-container {
        margin: 20px;
        padding: 20px;
    }

    .faq-container h1 {
        font-size: 1.8em;
    }

    .faq-question h2 {
        font-size: 1em;
    }
}
