/* font styling */
.light-text {
    font-family: 'Inter', sans-serif;
    font-weight: 300; /* Light weight */
  }
  
  /* For regular text */
  .regular-text {
    font-family: 'Inter', sans-serif;
    font-weight: 400; /* Regular weight */
  }
  
  /* For semi-bold text */
  .semi-bold-text {
    font-family: 'Inter', sans-serif;
    font-weight: 600; /* Semi-bold weight */
  }
  
  /* For bold text */
  .bold-text {
    font-family: 'Inter', sans-serif;
    font-weight: 700; /* Bold weight */
  }
  
  /* For extra bold text */
  .extra-bold-text {
    font-family: 'Inter', sans-serif;
    font-weight: 800; /* Bold weight */
  }

.privacy-policy-container {
    /* max-width: 900px; */
    /* margin: 50px auto; */
    padding: 30px;
    background-color: #fff; /* Background color */
    color: #000; /* Text color */
    border-radius: 8px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    max-width: 75%;
    margin: 50px auto;
  }
  
  .privacy-policy-container h1 {
    text-align: center;
    color: #2c3e50; /* Primary heading color */
    margin-bottom: 20px;
    /* font-size: 2.2em; */
    font-family: 'Inter', sans-serif;
    font-weight: 700; /* Bold weight */
  }
  
  .privacy-policy-container h2 {
    color: #000; /* Secondary heading color */
    margin-top: 30px;
    margin-bottom: 15px;
    font-size: 1.5em;
    border-bottom: 2px solid #eaeaea; /* Subtle underline */
    padding-bottom: 5px;
    font-family: 'Inter', sans-serif;
    font-weight: 600; /* Bold weight */
  }
  
  .privacy-policy-container p {
    line-height: 1.8;
    margin-bottom: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 400; /* Bold weight */
  }
  
  .privacy-policy-container strong {
    color: #000; /* Emphasized text color */
  }
  
  .back-to-top {
    display: block;
    margin: 40px auto;
    padding: 10px 20px;
    background-color: #000; /* Primary button color */
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .back-to-top:hover {
    background-color: #000; /* Darker shade for hover */
  }
  
  @media (max-width: 768px) {
    .privacy-policy-container {
      margin: 20px;
      padding: 20px;
    }
  
    .privacy-policy-container h1 {
      font-size: 1.8em;
    }
  
    .privacy-policy-container h2 {
      font-size: 1.3em;
    }
  }
  