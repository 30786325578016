
/* font styling */
body {
    font-family: 'Inter', sans-serif;
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;
  }
  
.header h1, nav a, .contact-button-text {
    font-family: 'Inter', sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
  }

  .contact-button-text{
    color: white;
  }
  
.header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 15px 20px;
    padding-left: 50px;
    padding-right: 50px;
    background-color: #fff;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    box-shadow: 0 4px 40px rgba(0, 0, 0, 0.1);
  }

.navframe {
    flex-grow: 1; /* This will make the navframe take up the available space */
    display: flex;
    justify-content: center; /* Center the navigation links */
    margin-right: 20px;
  }

  .logo {
    display: flex;
    align-items: center;
  }
  
  .logo img {
    width: 40px; /* Adjust the size according to your design */
    margin-right: 10px;
  }
  
  .navframe ul {
    list-style: none;
    display: flex;
    gap: 40px; /* Space between navigation links */
    margin: 0;
    padding: 0;
  }
  
  nav a {
    text-decoration: none;
    color: #333;
  }
  
  .smooth-scroll {
    scroll-behavior: smooth;
  }
  
  .contact-button {
    background: linear-gradient(45deg, #6675F7, #57007B);
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    /* font-weight: bold; */
    
  }
  
  .contact-button:hover{
    opacity: 0.8;
  }
  
/* mobile devices or small screens */

@media (max-width: 768px) {
    .header {
      flex-direction: column; /* Stack elements vertically */
      align-items: center; /* Align items to the start of the header */
      justify-content: space-around;
      padding: 15px 20px;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      width: 100%; /* Ensure the header takes the full width of its parent */
      box-sizing: border-box; 
    }
  
    .logo {
        display: flex;
        align-items: center;
        margin-right: 0; /* Remove right margin */
        margin-bottom: 10px; /* Add space below the logo */
    }
  
    .navframe {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
  
    .navframe ul {
      flex-direction: column; /* Stack nav items vertically */
      align-items: center; /* Align nav items to the start */
      gap: 10px; /* Reduce gap between nav items */
    }
  
    .contact-button {
      align-self: center; /* Make the button take full width */
      text-align: center; /* Center the text in the button */
      padding: 15px; /* Add more padding for touch targets */
      max-width: 100%; /* Ensure the button never exceeds the width of the parent */
      box-sizing: border-box;
    }
  }