/* font styling */
.light-text {
    font-family: 'Inter', sans-serif;
    font-weight: 300; /* Light weight */
}

.regular-text {
    font-family: 'Inter', sans-serif;
    font-weight: 400; /* Regular weight */
}

.semi-bold-text {
    font-family: 'Inter', sans-serif;
    font-weight: 600; /* Semi-bold weight */
}

.bold-text {
    font-family: 'Inter', sans-serif;
    font-weight: 700; /* Bold weight */
}

.extra-bold-text {
    font-family: 'Inter', sans-serif;
    font-weight: 800; /* Extra bold weight */
}

.terms-conditions-container {
    padding: 30px;
    background-color: #fff; /* Background color */
    color: #000; /* Text color */
    border-radius: 8px;
    max-width: 75%;
    margin: 50px auto;
}

.terms-conditions-container h1 {
    text-align: center;
    color: #2c3e50; /* Primary heading color */
    margin-bottom: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 700; /* Bold weight */
}

.terms-conditions-container h2 {
    color: #000; /* Secondary heading color */
    margin-top: 30px;
    margin-bottom: 15px;
    font-size: 1.5em;
    border-bottom: 2px solid #eaeaea; /* Subtle underline */
    padding-bottom: 5px;
    font-family: 'Inter', sans-serif;
    font-weight: 600; /* Bold weight */
}

.terms-conditions-container p {
    line-height: 1.8;
    margin-bottom: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 400; /* Regular weight */
}

.terms-conditions-container ul {
    margin-left: 20px;
    list-style-type: disc;
}

.terms-conditions-container ul li {
    margin-bottom: 10px;
    font-family: 'Inter', sans-serif;
    font-weight: 400; /* Regular weight */
}

.terms-conditions-container strong {
    color: #000; /* Emphasized text color */
}

.back-to-top {
    display: block;
    margin: 40px auto;
    padding: 10px 20px;
    background-color: #000; /* Primary button color */
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.back-to-top:hover {
    background-color: #333; /* Darker shade for hover */
}

@media (max-width: 768px) {
    .terms-conditions-container {
        margin: 20px;
        padding: 20px;
    }

    .terms-conditions-container h1 {
        font-size: 1.8em;
    }

    .terms-conditions-container h2 {
        font-size: 1.3em;
    }
}
